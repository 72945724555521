// --------------------
// Backstrap Custom CSS
// --------------------
// Styles applied after Bootstrap and CoreUI

body {
  background-color: $gray-200;
}

.card.bg-success {
  border-color: darken($success, 1%);
}

.btn-info {
  color: $white!important;
}

.btn-primary:hover, .btn-ghost-primary:hover, .btn-outline-primary:hover,
.btn-success:hover, .btn-ghost-success:hover, .btn-outline-success:hover,
.btn-danger:hover, .btn-ghost-danger:hover, .btn-outline-danger:hover,
.btn-info:hover, .btn-ghost-info:hover, .btn-outline-info:hover,
.btn-dark:hover, .btn-ghost-dark:hover, .btn-outline-dark:hover {
  color: $white!important;
}

.badge {
  margin: 0 0.2em;
}

.modal-primary .modal-content,
.modal-success .modal-content,
.modal-warning .modal-content,
.modal-danger .modal-content {
  border-color: $border-color;
  border-radius: 7px;
}

.breadcrumb {
  background: $muted-bg-color;
  border: none;
  font-size: 0.9em;
  margin: 10px 30px;
}

.breadcrumb-menu .btn {
  font-size: 0.9em;
}


.app-footer {
  background: transparent;
  border: none;
  font-size: 0.9em;
  padding: 10px 30px;
}

.sticky-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
}

// navbar-color

.navbar-color .navbar-brand:hover, .navbar-color .navbar-brand:focus {
  color: $white;
}

.navbar-color .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-color .navbar-nav .nav-link:hover, .navbar-color .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-color .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-color .navbar-nav .show > .nav-link,
.navbar-color .navbar-nav .active > .nav-link,
.navbar-color .navbar-nav .nav-link.show,
.navbar-color .navbar-nav .nav-link.active {
  color: $white;
}

.navbar-color .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-color .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-color .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-color .navbar-text a {
  color: $white;
}

.navbar-color .navbar-text a:hover, .navbar-color .navbar-text a:focus {
  color: $white;
}

.navbar-color.app-header .navbar-brand {
  color: $white;
}

// End of navbar-color


@media (max-width: 991.98px) {
  body:not(.header_fixed) .app-header {
    position: relative;
  }

  body:not(.header_fixed) .app-body {
    margin-top: 0px;
  }
}

.app-header {
  border-bottom: 1px solid rgba(0,40,100,.12);
}

.border-light {
  border: 1px solid rgba(0,40,100,.12);
}

.app-header .navbar-brand {
  color: #73818f;
}

.app-header.bg-light .navbar-brand {
  width: 250px;
  -ms-flex-pack: left;
  justify-content: left;
  padding: 0 2.25rem;
  color: #73818f;
  font-size: 1.4rem;
}

.app-header.bg-light .navbar-brand {
  opacity: 0.2;
}

.app-header.bg-light .navbar-brand:hover {
  opacity: 1;
}

// Cover use case: sidebar bg-light

.sidebar.bg-white .nav-link.active,
.sidebar.bg-white .nav-link:hover {
  color: white!important;
}

.sidebar.bg-white a:not(:hover),
.sidebar.bg-white a:not(:active),
.sidebar.bg-white a:not(:focus),
.sidebar.bg-white a:not(.open),
.sidebar.bg-white a:not(.active),
.sidebar.bg-white li {
  color: #495057!important;
}

.sidebar.bg-white .nav-dropdown.open {
  background: #fbfbfb;
  color: #495057!important;
}

.sidebar.bg-white .nav-link.active {
    background: #f6f6f6;
    color: $primary!important;
}

.sidebar.bg-white .nav-link.active .nav-icon,
.sidebar.bg-white .nav-link.active:hover .nav-icon {
    color: $primary!important;
}

// Cover use case: sidebar sidebar-pills

.sidebar-pills a.nav-link {
  color: white!important;
}

.sidebar-pills .nav-link.active,
.sidebar-pills .nav-link:hover
{
  color: $primary!important;
  // background: rgba(0,0,0,.04)!important;
}

.sidebar-pills .nav-link:hover .nav-icon {
  color: $primary!important;
}

.sidebar-pills a:not(:hover),
.sidebar-pills a:not(:active),
.sidebar-pills a:not(:focus),
.sidebar-pills a:not(.open),
.sidebar-pills a:not(.active),
.sidebar-pills li {
  color: #495057!important;
}

.sidebar-pills .nav-dropdown.open {
    // background: rgba(0,0,0,.04)!important;
}

.sidebar-pills {
  padding: 10px;
  width: 225px;
}

html:not([dir="rtl"]) .sidebar {
  margin-left: -225px;
}

.app-body .sidebar.sidebar-pills {
  flex: 0 0 225px;
  order: -1;
}

.sidebar.sidebar-pills .nav-link {
  color: #495057;
  border-radius: 3px;
  padding: 0.5rem 0.25rem 0.5rem 0.75rem;
}

.sidebar.sidebar-pills .nav-title {
  color: #495057;
  padding: 0.5rem 0.25rem 0.5rem 0.75rem;
}

.sidebar.sidebar-pills .nav-dropdown .nav-link:not(.nav-dropdown-toggle) {
  padding-left: 1.5rem;
}

.sidebar.sidebar-pills .nav-dropdown.open .nav-link.nav-dropdown-toggle {
  font-weight: 600;
}

.sidebar.sidebar-pills .nav-dropdown.open {
  background: $muted-bg-color;
  border-radius: 3px;
}

.sidebar.sidebar-pills .nav-dropdown.open .nav-link {
  color: #495057;
}

.sidebar.sidebar-pills .nav-link.active {
  font-weight: 500;
}

.sidebar.sidebar-pills .nav-link.active,
.sidebar.sidebar-pills .nav-link:hover {
  color: $primary!important;
  /*font-weight: 500;*/
  background-color: $muted-bg-color;
}

.sidebar.sidebar-pills .nav-link.active .nav-icon,
.sidebar.sidebar-pills .nav-link:hover .nav-icon {
  color: $primary;
}

.sidebar.sidebar-pills .nav-link .nav-icon {
  font-size: 1.2rem;
}

.sidebar.sidebar-pills .nav-link:hover.nav-dropdown-toggle::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}


h1 small,
h2 small,
h3 small,
h4 small,
h5 small {
  font-size: 0.5em;
  display: inline-block;
  padding-left: 0.25em;
}

.border-xs {
    border: 1px solid $border-color;
}

.shadow-xs {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.card {
    background-color: $white;
    background-clip: border-box;
    border: 1px solid $border-color;
    border-radius: 3px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.card-header {
  // border-bottom: 1px solid $border-color;
  // border-bottom: inherit;
  background: transparent;
}

.card-footer {
  border-top: 1px solid $border-color;
  background: transparent;
}

.card.bg-primary,
.card.bg-info,
.card.bg-warning,
.card.bg-danger {
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.brand-card {
  background-color: $white;
    background-clip: border-box;
    border: 1px solid $border-color;
    border-radius: 3px;
}

.aside-menu {
  border: 1px solid $border-color;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $white;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: $muted-bg-color;
}

.table-bordered th, .table-bordered td {
  border: 1px solid $border-color;
}

.table th, .table td {
  border-top: 1px solid $border-color;
}

.table th {
  background-color: $muted-bg-color;
}

.table thead th, .table thead td {
  /*border-bottom-width: 1px;
  border-bottom: 1px solid $border-color;*/
  border-bottom: none;
  border-top: none;
}

.bg-white {
  background-color: $white !important;
}

.bg-transparent {
  background-color: transparent !important;
}

/*  PAGINATION  */
.page-item.disabled .page-link {
  background-color: transparent;
}

/*  DATATABLE BUTTONS */

.btn:hover, .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  color: #495057;
  text-decoration: none;
}

.btn:focus, .dataTables_wrapper .dataTables_paginate .paginate_button:focus, .btn.focus, .dataTables_wrapper .dataTables_paginate .focus.paginate_button {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.btn.disabled, .dataTables_wrapper .dataTables_paginate .disabled.paginate_button, .btn:disabled, .dataTables_wrapper .dataTables_paginate .paginate_button:disabled {
  opacity: 0.65;
}

a.btn.disabled, .dataTables_wrapper .dataTables_paginate a.disabled.paginate_button,
fieldset:disabled a.btn,
fieldset:disabled .dataTables_wrapper .dataTables_paginate a.paginate_button,
.dataTables_wrapper .dataTables_paginate fieldset:disabled a.paginate_button {
  pointer-events: none;
}

.btn-primary, .dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:hover, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
  color: $white;
  background-color: darken($primary, 5%);
  border-color: darken($primary, 5%);
}

.btn-primary:focus, .dataTables_wrapper .dataTables_paginate .paginate_button.current:focus, .btn-primary.focus, .dataTables_wrapper .dataTables_paginate .focus.paginate_button.current {
  box-shadow: 0 0 0 2px rgba(98, 146, 214, 0.5);
}

.btn-primary.disabled, .dataTables_wrapper .dataTables_paginate .disabled.paginate_button.current, .btn-primary:disabled, .dataTables_wrapper .dataTables_paginate .paginate_button.current:disabled {
  color: $white;
  background-color: $primary;
  border-color: $primary;
}

.btn-primary:not(:disabled):not(.disabled):active, .dataTables_wrapper .dataTables_paginate .paginate_button.current:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .dataTables_wrapper .dataTables_paginate .paginate_button.current:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle,
.dataTables_wrapper .dataTables_paginate .show > .dropdown-toggle.paginate_button.current {
  color: $white;
  background-color: darken($primary, 5%);
  border-color: darken($primary, 5%);
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .dataTables_wrapper .dataTables_paginate .paginate_button.current:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .dataTables_wrapper .dataTables_paginate .paginate_button.current:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.dataTables_wrapper .dataTables_paginate .show > .dropdown-toggle.paginate_button.current:focus {
  box-shadow: 0 0 0 2px rgba(98, 146, 214, 0.5);
}

/*  DROPDOWN  */

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: #495057;
  text-align: left;
  list-style: none;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $border-color;
  border-radius: 3px;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1280px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid $gray-200;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: $black;
  text-decoration: none;
  background-color: $gray-100;
}

.dropdown-item.active, .dropdown-item:active {
  color: $white;
  text-decoration: none;
  background-color: $primary;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  background: transparent;
  color: $gray-700;
  border: none;
  padding: 0.3rem 1rem;
  display: block;
  margin-bottom: 0;
  font-size: 0.875rem;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: $black;
}

/*  FORMS */
.form-control, .dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_filter input {
  display: block;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: $gray-700;
  background-color: $white;
  background-clip: padding-box;
  border: 1px solid $border-color;
  border-radius: 3px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

@media (prefers-reduced-motion: reduce) {
  .form-control, .dataTables_wrapper .dataTables_length select, .dataTables_wrapper .dataTables_filter input {
    transition: none;
  }
}

.form-control::-ms-expand, .dataTables_wrapper .dataTables_length select::-ms-expand, .dataTables_wrapper .dataTables_filter input::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus, .dataTables_wrapper .dataTables_length select:focus, .dataTables_wrapper .dataTables_filter input:focus {
  color: #495057;
  background-color: $white;
  border-color: lighten($primary, 5%);
  outline: 0;
  box-shadow: 0 0 0 2px lighten($primary, 25%);
}

.form-control::-webkit-input-placeholder, .dataTables_wrapper .dataTables_length select::-webkit-input-placeholder, .dataTables_wrapper .dataTables_filter input::-webkit-input-placeholder {
  color: $gray-300;
  opacity: 1;
}

.form-control::-moz-placeholder, .dataTables_wrapper .dataTables_length select::-moz-placeholder, .dataTables_wrapper .dataTables_filter input::-moz-placeholder {
  color: $gray-300;
  opacity: 1;
}

.form-control::-ms-input-placeholder, .dataTables_wrapper .dataTables_length select::-ms-input-placeholder, .dataTables_wrapper .dataTables_filter input::-ms-input-placeholder {
  color: $gray-300;
  opacity: 1;
}

.form-control:-ms-input-placeholder, .dataTables_wrapper .dataTables_length select:-ms-input-placeholder, .dataTables_wrapper .dataTables_filter input:-ms-input-placeholder {
  color: $gray-300;
  opacity: 1;
}

.form-control::placeholder, .dataTables_wrapper .dataTables_length select::placeholder, .dataTables_wrapper .dataTables_filter input::placeholder {
  color: $gray-300;
  opacity: 1;
}

.form-control:disabled, .dataTables_wrapper .dataTables_length select:disabled, .dataTables_wrapper .dataTables_filter input:disabled, .form-control[readonly], .dataTables_wrapper .dataTables_length select[readonly], .dataTables_wrapper .dataTables_filter input[readonly] {
  background-color: #f8f9fa;
  opacity: 1;
}

select.form-control:focus::-ms-value, .dataTables_wrapper .dataTables_length select:focus::-ms-value {
  color: #495057;
  background-color: $white;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.bold-labels label {
  font-weight: 600;
}

/* TABS */
.tab-content {
  background-color: $white;
  background-clip: border-box;
  border-radius: 3px;
  border: 1px solid $border-color;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-tabs .nav-link.active:focus {
  border-color: $border-color;
  border-bottom: 1px solid white;
}

table.table-hover tbody tr:hover {
  background-color: $hover-color;
}

/*  TAGS  */


.tag {
  font-size: 0.75rem;
  color: #6e7687;
  background-color: #e9ecef;
  border-radius: 3px;
  padding: 0 .5rem;
  line-height: 2em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

a.tag {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;
}

a.tag:hover {
  background-color: rgba(110, 118, 135, 0.2);
  color: inherit;
}

.tag-addon {
  display: inline-block;
  padding: 0 .5rem;
  color: inherit;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.06);
  margin: 0 -.5rem 0 .5rem;
  text-align: center;
  min-width: 1.5rem;
}

.tag-addon:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.tag-addon i {
  vertical-align: middle;
  margin: 0 -.25rem;
}

a.tag-addon {
  text-decoration: none;
  cursor: pointer;
  transition: .3s color, .3s background;
}

a.tag-addon:hover {
  background: rgba(0, 0, 0, 0.16);
  color: inherit;
}

.tag-avatar {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 3px 0 0 3px;
  margin: 0 .5rem 0 -.5rem;
}

.tag-blue {
  background-color: $primary;
  color: $white;
}

.tag-indigo {
  background-color: $indigo;
  color: $white;
}

.tag-purple {
  background-color: $purple;
  color: $white;
}

.tag-pink {
  background-color: $pink;
  color: $white;
}

.tag-red {
  background-color: $red;
  color: $white;
}

.tag-orange {
  background-color: $orange;
  color: $white;
}

.tag-yellow {
  background-color: $yellow;
  color: $white;
}

.tag-green {
  background-color: $green;
  color: $white;
}

.tag-teal {
  background-color: $teal;
  color: $white;
}

.tag-cyan {
  background-color: $cyan;
  color: $white;
}

.tag-white {
  background-color: $white;
  color: $white;
}

.tag-gray {
  background-color: #868e96;
  color: $white;
}

.tag-gray-dark {
  background-color: #343a40;
  color: $white;
}

.tag-azure {
  background-color: #45aaf2;
  color: $white;
}

.tag-lime {
  background-color: #7bd235;
  color: $white;
}

.tag-primary {
  background-color: $primary;
  color: $white;
}

.tag-secondary {
  background-color: $secondary;
  color: $white;
}

.tag-success {
  background-color: $success;
  color: $white;
}

.tag-info {
  background-color: $light-blue;
  color: $white;
}

.tag-warning {
  background-color: $warning;
  color: $white;
}

.tag-danger {
  background-color: $danger;
  color: $white;
}

.tag-light {
  background-color: $light;
  color: $white;
}

.tag-dark {
  background-color: $dark;
  color: $white;
}

.tag-rounded {
  border-radius: 50px;
}

.tag-rounded .tag-avatar {
  border-radius: 50px;
}

.tags {
  margin-bottom: -.5rem;
  font-size: 0;
}

.tags > .tag {
  margin-bottom: .5rem;
}

.tags > .tag:not(:last-child) {
  margin-right: .5rem;
}

/*  JUMBOTRON */

.jumbotron {
  background-color: $muted-bg-color;
}

/* SELECT 2  */

.select2-container--bootstrap .select2-selection {
    box-shadow: none!important;
    -webkit-box-shadow: none!important;
    border: 1px solid $border-color!important;
}

.select2-container--bootstrap.select2-container--focus .select2-selection,
.select2-container--bootstrap.select2-container--open .select2-selection {
    box-shadow: none!important;
    -webkit-box-shadow: none!important;
}

.select2-container--bootstrap .select2-dropdown {
    border-color: $border-color!important;
}

/*  PACE JS  */

.pace {
  -webkit-pointer-events: none;
  pointer-events: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: $primary;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

// BOOTSTRAP ALERTS

.alert a.alert-link {
  color: inherit!important;
  font-weight: 400;
  text-decoration: underline!important;
}

/*# sourceMappingURL=backstrap.css.map */