// -------------------
// Backstrap Variables
// -------------------
// They override CoreUI and Bootstrap variables

// Alerts

$alert-bg-level:                    0 !default;
$alert-border-level:                +1 !default;
$alert-color-level:                 -12 !default;

// Inputs and buttons

$input-btn-focus-width:       0rem !default;

// General

$body-bg:                     $gray-100 !default;

// stylelint-disable value-keyword-case
$font-family-sans-serif:      "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:            $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;


// Modals

$modal-content-border-color:        rgba($black, .1) !default;
$modal-header-border-color:         $modal-content-border-color !default;
