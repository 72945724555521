@import "backstrap_colors";

$primary:       $purple !default;
$secondary:     $gray-300 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $black !default;

$hover-color: rgba(124, 105, 239, 0.1);
$border-color: rgba(0, 40, 100, 0.12);
$muted-bg-color: rgba(0, 0, 0, 0.02);

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "default":    $secondary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "notice":     $info,
    "warning":    $warning,
    "danger":     $danger,
    "error":      $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

@import "backstrap_miscellaneous";