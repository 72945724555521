// TODO: 
// - make the noty bubbles background colors depend on SCSS variables (primary, secondary, etc)
// - make sure anchors inside noty bubbles are same color as text, and underlined
// - make sure noty progress bar is a correct color for each notification type 

.noty_theme__backstrap.noty_bar {
  margin: 4px 0;
  overflow: hidden;
  position: relative;
  border: 1px solid transparent;
  border-radius: .25rem; }
  .noty_theme__backstrap.noty_bar .noty_body {
    padding: .75rem 1.25rem; 
    font-weight: 300;
  }
  .noty_theme__backstrap.noty_bar .noty_buttons {
    padding: 10px; }
  .noty_theme__backstrap.noty_bar .noty_close_button {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: $black;
    text-shadow: 0 1px 0 $white;
    filter: alpha(opacity=20);
    opacity: .5;
    background: transparent; }
  .noty_theme__backstrap.noty_bar .noty_close_button:hover {
    background: transparent;
    text-decoration: none;
    cursor: pointer;
    filter: alpha(opacity=50);
    opacity: .75; }

.noty_theme__backstrap.noty_type__note,
.noty_theme__backstrap.noty_type__notice,
.noty_theme__backstrap.noty_type__alert,
.noty_theme__backstrap.noty_type__notification {
  background-color: $white;
  color: inherit; }

.noty_theme__backstrap.noty_type__warning {
  color: $gray-100;
  background-color: $yellow;
  border-color: darken($yellow, 10%);
}

.noty_theme__backstrap.noty_type__danger,
.noty_theme__backstrap.noty_type__error {
  color: $gray-100;
  background-color: $danger;
  border-color: darken($danger, 10%);
}

.noty_theme__backstrap.noty_type__info,
.noty_theme__backstrap.noty_type__information {
    color: $gray-100;
    background-color: $info;
    border-color: darken($info, 10%);
}

.noty_theme__backstrap.noty_type__success {
  color: $gray-100;
  background-color: $success;
  border-color: darken($success, 10%);
}

.noty_theme__backstrap.noty_type__primary {
  color: $gray-100;
  background-color: $primary;
  border-color: darken($primary, 10%);
}

.noty_theme__backstrap.noty_type__secondary {
  color: $black;
  background-color: $secondary;
  border-color: darken($secondary, 10%);
}

.noty_theme__backstrap.noty_type__light {
  color: $black;
  background-color: $light;
  border-color: darken($light, 10%);
}

.noty_theme__backstrap.noty_type__dark {
  color: $gray-100;
  background-color: $dark;
  border-color: darken($dark, 10%);
}